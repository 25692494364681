import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import ToastDialog from '../dialogs/ToastDialog';
import RewardOptions from '../other-components/RewardOptions';
import { setCookie, getCookie, deleteCookie } from '../modals/Cookie';
import { API_ACCESS_URL, LOGIN_REDIRECT_URL, redirectTo } from '../modals/Constants';
import BottomNavbar from '../other-components/BottomNavbar';

function Home() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    accountBalance: 0,
    purchaseDialogInvestId: "",
    purchaseDialogTitle: "",
    purchaseDialogDetails: "",
    purchaseDialogHourIncome: "",
    purchaseDialogDailyIncome: "",
    purchaseDialogTotalIncome: "",
    purchaseDialogTotalDays: "",
    purchaseDialogShow: false,
    purchaseDialogTheme: "dlg-thm-green",
    toastMessage: "",
    toastDialogShow: false,
    slideShowImage: "",
    recordList: [],
  });

  const [marketPricing, setMarketPricing] = useState(null);

  const updateToastDialogState = (data, msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  function getAccountInfo() {
    const fecthApiData = async (url) => {

      try {
        const res = await fetch(url);
        const data = await res.json();

        if (data.status_code == "success") {
          let accountMobileNum = data.data[0]['account_mobile_num'];
          let accountBalance = data.data[0]['account_balance'];
          let accountWinningBalance = data.data[0]['account_w_balance'];
          let accountCoinsBalance = data.data[0]['account_c_balance'];
          let service_app_status = data.data[0]['service_app_status'];
          let service_min_recharge = data.data[0]['service_min_recharge'];
          let service_min_withdraw = data.data[0]['service_min_withdraw'];
          let service_recharge_option = data.data[0]['service_recharge_option'];
          let service_telegram_url = data.data[0]['service_telegram_url'];
          let service_app_download_url = data.data[0]['service_app_download_url'];
          let slide_show_banner = data.data[0]['slide_show_banner'];
          let service_withdrawal_status = data.data[0]['service_withdrawal_status'];

          setCookie("balance", accountBalance, 30);
          setCookie("cbalance", accountCoinsBalance, 30);
          setCookie("wbalance", accountWinningBalance, 30);
          setCookie("minrecharge", service_min_recharge, 30);
          setCookie("minwithdraw", service_min_withdraw, 30);
          setCookie("withdrawalEnabled", service_withdrawal_status, 30);
          setCookie("rechargeoptions", service_recharge_option, 30);
          setCookie("telegramURL", service_telegram_url, 30);
          setCookie("appDownloadURL", service_app_download_url, 30);
          setConstants({ ...pageConst, accountBalance: accountBalance, slideShowImage: slide_show_banner });

          if (service_app_status == "OFF") {
            navigate('/um', { replace: true });
          }
        } else if (data.status_code == "account_error") {
          if (deleteCookie(1)) {
            navigate('/login', { replace: true });
          }
        }

      } catch (error) {
      }
    };


    if (getCookie("uid")) {
      fecthApiData(API_ACCESS_URL + "request-account-info.php?USER_ID=" + getCookie("uid") + "&SECRET_KEY=" + getCookie("secret") + "&APP_VERSION=3");
    } else {
      redirectTo(LOGIN_REDIRECT_URL);
    }

  }

  function isTrue() {
    return Math.random() < 0.5;
  }

  function getCurrencyMovement() {
    return (Math.random() * (0.10000 - 0.00001) + 0.00001).toFixed(5);
  }

  function getCurrencyPrice(currency) {
    if (currency == "BTC") {
      return (Math.random() * (69000 - 67000) + 67000).toFixed(2);
    } else if (currency == "ETC") {
      return ((Math.random() * (18 - 17.50)) + 17.50).toFixed(2);
    } else if (currency == "BNB") {
      return ((Math.random() * (600 - 540)) + 540).toFixed(2);
    } else if (currency == "LTC") {
      return ((Math.random() * (71 - 64)) + 64).toFixed(2);
    } else if (currency == "TRX") {
      return ((Math.random() * (14 - 13)) + 13).toFixed(2);
    }
  }

  function graphMaker() {
    const marketList = (
      <>
        <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-20'>
          <div>BTC/<span className='cl-l-grey'>USDT</span></div>
          <div>{getCurrencyPrice("BTC")}</div>
          {isTrue() ?
            <div className='v-center cl-green-new'>
              <img
                src={require("../icons/up_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>
            :
            <div className='v-center cl-red-new'>
              <img
                src={require("../icons/down_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>}
        </div>

        <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-15'>
          <div>ETC/<span className='cl-l-grey'>USDT</span></div>
          <div>{getCurrencyPrice("ETC")}</div>
          {isTrue() ?
            <div className='v-center cl-green-new'>
              <img
                src={require("../icons/up_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>
            :
            <div className='v-center cl-red-new'>
              <img
                src={require("../icons/down_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>}
        </div>

        <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-15'>
          <div>BNB/<span className='cl-l-grey'>USDT</span></div>
          <div>{getCurrencyPrice("BNB")}</div>
          {isTrue() ?
            <div className='v-center cl-green-new'>
              <img
                src={require("../icons/up_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>
            :
            <div className='v-center cl-red-new'>
              <img
                src={require("../icons/down_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>}
        </div>

        <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-15'>
          <div>LTC/<span className='cl-l-grey'>USDT</span></div>
          <div>{getCurrencyPrice("LTC")}</div>
          {isTrue() ?
            <div className='v-center cl-green-new'>
              <img
                src={require("../icons/up_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>
            :
            <div className='v-center cl-red-new'>
              <img
                src={require("../icons/down_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>}
        </div>

        <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-15'>
          <div>TRX/<span className='cl-l-grey'>USDT</span></div>
          <div>{getCurrencyPrice("TRX")}</div>
          {isTrue() ?
            <div className='v-center cl-green-new'>
              <img
                src={require("../icons/up_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>
            :
            <div className='v-center cl-red-new'>
              <img
                src={require("../icons/down_icon.png")}
                className="h-15-p"
                alt="icon"
              />
              &nbsp; {getCurrencyMovement()}
            </div>}
        </div>
      </>
    );

    setMarketPricing(marketList);
  }

  function getUser() {
    return 'User****' + (Math.floor(Math.random() * 9000) + 1000);
  }

  function getAmount() {
    let randomNumber = Math.floor(Math.random() * (999 - 100 + 1)) * 10;
    randomNumber += (Math.random() < 0.5 ? 0 : 5);
    return randomNumber;
  }

  const [withdrawalItems, setWithdrawals] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setWithdrawals((prevItems) => {
        const dataList = (
          <>
            <div className='w-100 row-view sb-view cl-l-grey ft-sz-14 mg-t-15'>
              <div>{getUser()}</div>
              <div>₹{getAmount()}</div>
            </div>
          </>
        );

        const updatedItems = [...prevItems, dataList];

        if (updatedItems.length > 10) {
          updatedItems.shift();  // Remove the first item
        }

        return updatedItems;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  function fillAllWithdrawals() {
    if (withdrawalItems.length <= 0) {
      setWithdrawals((prevItems) => {
        const dataList = [];

        for (let i = 0; i < 10; i++) {
          dataList.push(
            <div className='w-100 row-view sb-view cl-l-grey ft-sz-14 mg-t-15' key={i}>
              <div>{getUser()}</div>
              <div>₹{getAmount()}</div>
            </div>
          );
        }

        const updatedItems = [...prevItems, ...dataList];
        return updatedItems;
      });
    }
  }

  useEffect(() => {
    graphMaker();

    const interval = setInterval(() => {
      graphMaker();
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fillAllWithdrawals();
    getAccountInfo();
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y bg-tar-black hide-sb">
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />

        <div className='w-100 min-h-160'>
          <img className='w-100 h-auto' src={pageConst.slideShowImage} />
        </div>

        <RewardOptions />

        <div className='col-view pd-10-20 mg-b-150'>

          <div className='row-view sb-view mg-t-15'>
            <div>
              <div className='cl-white'>My Balance</div>
              <div className='cl-orange ft-sz-18 mg-t-5'>₹{getCookie("balance")}</div>
            </div>

            <div className='txt-a-end'>
              <div className='cl-white'>My Earnings</div>
              <div className='cl-orange ft-sz-18 mg-t-5'>₹{getCookie("wbalance")}</div>
            </div>
          </div>

          <div className='pd-20 br-10 bg-l-white mg-t-30'>
            <div className='cl-white'>Market</div>

            <div className='w-100 ft-sz-14 row-view cl-white ovf-scrl-x mg-t-10 hide-sb'>
              <div className='pd-5-15 br-20 bg-grad-lgt-drk-blue'>Huobipro</div>
              <div className='pd-5-15 mg-l-5 br-20 bg-tar-black'>Okex</div>
              <div className='pd-5-15 mg-l-5 br-20 bg-tar-black'>Binance</div>
              <div className='pd-5-15 mg-l-5 br-20 bg-tar-black'>Coinbase</div>
            </div>

            <div className='w-100 ft-sz-14 row-view sb-view cl-white mg-t-30'>
              <div>Name</div>
              <div className='mg-l-20'>Price</div>
              <div>Movement</div>
            </div>

            <div>{marketPricing}</div>

          </div>

          <div className='pd-20 br-10 bg-l-white mg-t-30'>
            <div className='w-100 row-view sb-view cl-white'>
              <div>User</div>
              <div>Withdrawals</div>
            </div>

            {withdrawalItems.map((item, index) => (
              <div key={index} >
                {item}
              </div>
            ))}
          </div >

        </div>

        <BottomNavbar activeBar="home" />
      </div>
    </div >
  );
}

export default Home;